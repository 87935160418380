<template>
  <div>
    <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="black" size="128" is-full-screen />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center"><span style="color: #fff">
            {{ msg }}
          </span></v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex lg11>

        <v-layout wrap justify-center pt-10 px-5>
          <v-flex lg6>
            <span style="font-family: Montserrat-Bold; font-size: 28px;">Regions</span>
          </v-flex>
      <v-flex lg6 class="text-end" align-self-end>
        <v-btn color="#00A08E" @click="addRegionDialog = true"><span style="font-family: Montserrat-Regular; font-weight: 600; color: white">Add Region</span></v-btn>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-start>
      <v-flex xs12 sm4 md3 lg3 pt-4 v-for="(item, index) in region" :key="index" class="my-2" pl-3 pr-3>
        <v-card class="tile">
            <v-layout wrap justify-center pa-5>
                <v-flex md12 lg12 align-self-center>
                    <span style="font-family: Montserrat-Regular; font-size: 16px; font-weight: 600; color: black;">{{ item.name }}</span>
                </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
                <v-flex xs6 sm6 md6 lg6 class="text-center">
                    <v-btn small color="#00A08E" @click="regionEdit(item._id)"><span style="font-family: Montserrat-Regular; font-weight: 600; color: white">Edit</span></v-btn>
                </v-flex>
                <v-flex xs6 sm6 md6 lg6 class="text-center">
                    <v-btn small @click="deleteRegion(item._id)"><span style="font-family: Montserrat-Regular; font-weight: 600;">Delete</span></v-btn>
                </v-flex>
            </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

      </v-flex>
    </v-layout>

    

    <v-dialog
      v-model="addRegionDialog"
      width="400px"
      content-class="dialog-custom"
    >
      <v-card>
        <v-layout wrap justify-center pa-8 pt-6>
          <v-flex xs12 sm12 md12 lg12 xl12>
            <v-layout wrap justify-center>
              <v-flex xs11 sm11 md11 lg11 pb-3>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 24px;
                    font-weight: 700;
                  "
                  >New Region
                </span>
              </v-flex>
              <v-flex xs1 sm1 md1 lg1>
                <v-btn icon>
                  <v-icon @click="addRegionDialog = false">mdi-close</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                >
                  Region Name
                </span>
              </v-flex>
              <v-flex  xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  type="text"
                  placeholder="Name"
                  v-model="regionName"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-5 class="text-center">
                <v-btn color="rgba(0, 160, 142, 1)" @click="createRegion">
                  <span
                    style="
                      color: white;
                      font-family: Montserrat-Regular;
                      font-size: 14px;
                      font-weight: 600;
                      text-transform: none;
                    "
                  >
                    Create Region
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editRegionDialog"
      width="400px"
      content-class="dialog-custom"
    >
      <v-card>
        <v-layout wrap justify-center pa-8 pt-6>
          <v-flex xs12 sm12 md12 lg12>
            <v-layout wrap justify-center>
              <v-flex xs11 sm11 md11 lg11 pb-3>
                <span
                  style="
                    font-family: Montserrat-Regular;
                    font-size: 24px;
                    font-weight: 700;
                  "
                  >Edit Region
                </span>
              </v-flex>
              <v-flex xs1 sm1 md1 lg1>
                <v-btn icon>
                  <v-icon @click="editRegionDialog = false">mdi-close</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12>
                <span
                  style="
                    font-family: Lato-Regular;
                    font-size: 16px;
                    font-weight: 500;
                  "
                >
                  Region Name
                </span>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field
                  outlined
                  dense
                  type="text"
                  placeholder="Name"
                  v-model="editRegionName"
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-5 class="text-center">
                <v-btn color="rgba(0, 160, 142, 1)" @click="editRegion()">
                  <span
                    style="
                      color: white;
                      font-family: Montserrat-Regular;
                      font-size: 14px;
                      font-weight: 600;
                      text-transform: none;
                    "
                  >
                    Submit
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>

import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      region: [],
      addRegionDialog: false,
      regionName: "",
      editRegionDialog: false,
      editRegionName: "",
      regionId: "",
    };
  },
  mounted() {
    this.getRegion();
  },
  methods: {
    getRegion() {
      this.appLoading = true;
      axios({
        url: "/region/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.region = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    createRegion() {
      axios({
        method: "POST",
        url: "/region/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          name: this.regionName,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.getRegion();
            this.addRegionDialog = false; 
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    regionEdit(id){
      this.editRegionDialog = true;
      this.regionId = id;
    },

    editRegion(){
      axios({
        method: "POST",
        url: "/region/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.regionId,
          name: this.editRegionName,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.editRegionDialog = false;
            this.getRegion();
            this.addRegionDialog = false; 
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteRegion(id){
      axios({
        method: "POST",
        url: '/region/delete/' + id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.getRegion();
            this.addRegionDialog = false; 
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>


<style scoped>
.tile {
  border-radius: 10px;
  height: 115px;
}
</style>
